import React, { PureComponent } from 'react'
import { RichText } from 'prismic-reactjs'

import Layout from '../components/layout'
import SEO from '../components/seo'

import { createRichTextSerializer } from '../utils/prismic'

const descriptionSerializer = createRichTextSerializer({
  paragraphClassname: () => 'font-sans leading-loose mt-4 text-base',
  heading2Classname: () => 'font-normal text-xl mt-8',
  ulClassname: () => 'font-sans leading-loose mt-4 text-base',
  aClassname: () => 'text-blue-dianne hover:no-underline',
})

export default class ApplyPage extends PureComponent {
  render() {
    const { job } = this.props.pageContext
    return (
      <Layout>
        <SEO title={job.title} keywords={[`longevity`]} />

        <div className="container lg:max-w-xl px-8 lg:px-0 mx-auto mt-16 mb-24 lg:mb-32 font-serif text-blue-dianne leading-tight">
          <h1 className="text-2xl px-8 lg:px-0 lg:text-headline font-normal">
            {job.title}
          </h1>
          <p className="font-sans leading-normal px-8 lg:px-0 mt-4">
            {job.location}
          </p>
          <div className="mt-16 lg:mt-32">
            <div className="lg:w-2/3 px-8 lg:pl-0 lg:pr-8">
              {RichText.render(
                job.description,
                undefined,
                descriptionSerializer
              )}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
